import React from 'react';

function IconShield() {
  return (
    <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(1, 0, 0, 1, -8.139717, -3.668472)">
        <path d="M 253.875 20.844 C 192.33 21.249 130.228 30.384 66.405 48.656 L 59.938 50.5 L 59.658 57.22 C 53.253 204.68 108.778 403.845 251.938 486.5 L 261.282 486.5 C 400.312 406.23 463.162 204.736 456.658 57.22 L 456.533 51.313 L 449.909 48.656 C 386.279 30.441 323.339 21.006 259.847 20.844 C 257.863 20.839 255.863 20.831 253.877 20.844 L 253.875 20.844 Z"/>
      </g>
    </svg>
  );
}

export default IconShield;
